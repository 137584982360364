#fomo {
  z-index: 9999;
  box-shadow: 0px 0px 15px #00000029;
  border-radius: 8px;
  padding: 10px 0;
  background-color: white;
  position: fixed;
  bottom: -110px;
  left: 15px;
  left: 0px;
  height: 75px;
  width: 100%;
  // width: 100%;
  border: 3px solid #f4f4f4;
  color: #222 !important;
  padding: 0 15px;
  transition: all 400ms;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media (min-width: 426px) {
    width: 400px;
  }
  &.is-visible {
    bottom: 15px;
    //left: 15px;
  }

  .product_image {
    flex: 0 0 auto;
    height: 80%;
    //border-right: 3px solid #f4f4f4;
  }

  .wrapper {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 75%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 25%;

    //padding-left: 10px;
    .buyer {
      font-size: 13px;
      color: #222;
      span {
        color: #222;
      }
    }

    .product_name {
      font-weight: 600;
      text-decoration: none;
      color: #00bf42;
      line-height: 2;
    }

    .time {
      font-size: 13px;
      color: #222;
      span {
        color: #222;
      }
    }

    .amount {
      font-weight: bold;
    }
  }
}
